const projects = [
    {
      image: "/assets/images/projects/vnsh.gif",
      name: "Vanishing Email",
      timeframe: "Jun 2024 - Present",
      description: "This tool is a follow-up to my other tools in the VNSH suite VNSH.Link and VNSH.Chat. With privacy in mind I wanted to create a ephemeral email service so I didn't have to provide my real email address to ephemeral product sign-ups.",
      link: "https://vnsh.net",
      technologies: ["Angular", "Express", "ElasticUi", "MailGun API", "Docker", "Redis"]
    },
    {
      image: "/assets/images/projects/vnsh.gif",
      name: "Vanishing Chat",
      timeframe: "Jun 2024 - Present",
      description: "This tool is a follow-up to my other tool VNSH.Link. While I love the ability to easily share data securely between devices using a one time link, sometimes there needs to be a conversation around the data. This tool allows you to share a link to an encrypted chat room that is ephemeral and secure.",
      link: "https://vnsh.chat",
      technologies: ["Angular", "Express", "ElasticUi", "Docker", "Encryption", "WebSockets"]
    },
    {
      image: "/assets/images/projects/vnsh.gif",
      name: "Vanishing Link",
      timeframe: "Nov 2023 - Present",
      description: "This tool was originally created to simplify the process of securely sharing passwords or secrets outside of normal communication channels. The generated link, while it may be memorable, is designed to be accessible only once, after which the underlying data is deleted.",
      link: "https://vnsh.link",
      technologies: ["React", "Node.js", "ElasticUi", "Docker", "Encryption", "Redis"]
    },
    {
      image: "/assets/images/me.jpg",
      name: "Resume Site",
      timeframe: "Jul 2024 - Present",
      description: "I decided I needed a new way to showcase my work and decided to build this site. I wanted to keep it simple and easy to navigate.",
      link: "https://resume.egli.me",
      technologies: ["React", "MaterialUi", "Docker"]
    },
    {
      image: "/assets/images/me.jpg",
      name: "Spelling Game v2",
      timeframe: "Jul 2024 - Present",
      description: "This is a full rewrite using react of the original spelling game. This version is designed to be more user friendly and easier to maintain and add additional features.",
      link: "https://spelling.egli.me",
      technologies: ["React", "MaterialUI", "Docker"]
    },
    {
      image: "/assets/images/me.jpg",
      name: "DeckShare - Decky Plugin",
      timeframe: "Mar 2024",
      description: "SteamOS Screenshot Sharing Service for Decky Plugin Loader",
      link: "https://deckshare.zip/",
      technologies: ["React", "Python", "Flask", "Discord API"]
    },
    {
      image: "/assets/images/me.jpg",
      name: "DeckShare",
      timeframe: "Feb 2024",
      description: "SteamOS Screenshot Sharing Service",
      link: "https://github.com/SmugZombie/DeckShare",
      technologies: ["Python", "Discord API"]
    },
    {
      image: "/assets/images/me.jpg",
      name: "PortSpawner",
      timeframe: "Feb 2024",
      description: "Offline Now due to Cost - A simple tool to spawn temporary ports for testing using a Node application that controls the lifecycle of docker containers that were specially crafted to listen via UDP or TCP on a ephemeral port managed by the Node manager. A simple display on the website would show you if you can reach the port via TCP and a listener console appears to allow you to test sending UDP requests.",
      link: "",
      technologies: ["Python", "Discord API"]
    },
    {
      image: "/assets/images/me.jpg",
      name: "ZombShootr",
      timeframe: "Jan 2024",
      description: "A Windows application built using C# allows the user to use their webcam to point it at anything they desire to use as a indoor lazer target. The app would then allow the user to create special zones for targets and then shoot them with a lazer gun. The app would keep score and allow the user to compete against others.",
      link: "",
      technologies: ["Python", "Discord API"]
    },
    {
      image: "/assets/images/me.jpg",
      name: "Spelling Game v1",
      timeframe: "Jan 2022",
      description: "To better aid my children in learning to spell I created a simple spelling game. This game is designed to be simple and easy to use.",
      link: "https://spelling.egli.me",
      technologies: ["HTML", "JavaScript"]
    },
    {
      image: "/assets/images/projects/breachradar.jpg",
      name: "BreachRadar Sensor",
      timeframe: "Jan 2013 - Jan 2020",
      description: "No Longer Available - A remote sensor deployed as an Imaged Raspberry Pi to monitor network traffic and report back to a central server to client locations without access to an always-on machine.",
      link: "",
      technologies: ["PHP", "OPENVpn", "SYSLOG", "Python"],
    },
    {
      image: "/assets/images/projects/breachradar.jpg",
      name: "BreachRadar Frontend/Backend/Agents",
      timeframe: "Jan 2013 - Jan 2020",
      description: "No Longer Available - Breach Radar was designed as a simple security solution targeting Mom and Pop franchises to assist with PCI compliance and monitoring. An agent was deployed to all available endpoints (Windows and Mac) and reported back both FIM and Logging to a central aggrigation point for analsys and analyst assisted monitoring. Breach Radar once boasted significant impact. At its zenith, it safeguarded over 15k client endpoints simultaneously, spanning hundreds of companies.",
      link: "",
      technologies: ["PHP", "OSSEC", "MySQL", "Python", "AutoHotKey", "SYSLOG", "ElasicSearch", "Kibana"], 
    },
    {
      image: "/assets/images/me.jpg",
      name: "DigDNS 2.0",
      timeframe: "Jan 2015",
      description: "A newer version of the original DigDNS tool. This version was designed to be more user friendly and easier to navigate.",
      link: "https://digdns.com",
      technologies: ["PHP", "JQuery", "DNS"]
    },
    {
      image: "/assets/images/me.jpg",
      name: "DigDNS Legacy",
      timeframe: "Oct 2010",
      description: "Designed to help troubleshoot website dns issues created during my time at GoDaddy to assist myself and other representatives in troubleshooting DNS issues.",
      link: "https://digdns.com",
      technologies: ["PHP", "JQuery", "DNS"]
    },
    {
      image: "/assets/images/me.jpg",
      name: "And a plethora of others..",
      timeframe: "Forever",
      description: "There are more projects than I can count that I have started in my tenure as a developer and many that I have completed. Too many to list here unfortunately, I am always looking for new projects to work on and new technologies to learn.",
      link: "",
      technologies: ["Eduction", "Learning", "Innovation"]
    },
  ];

  module.exports.projects = projects;