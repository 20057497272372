import React from 'react';
import { createTheme, ThemeProvider, CssBaseline, Container, Box, Grid, Link } from '@mui/material';
import GeneralInfo from './components/GeneralInfo';
import Resume from './components/Resume';
import Projects from './components/Projects';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container maxWidth={false} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
      <Box sx={{ mb: 2, width: '100%' }}>
        <GeneralInfo />
      </Box>
      <Box sx={{ width: '100%', maxWidth: '1440px' }}>
        <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <Resume />
          </Grid>
          <Grid item xs={12} md={6}>
            <Projects />
          </Grid>
      </Grid>
      </Box>
      <footer style={{ textAlign: 'center', marginTop: '20px' }}>
        <p>© 2024 - Resume App - <Link href='https://github.com/smugzombie' target='_blank'>Ron Egli</Link></p>
      </footer>
    </Container>
    </ThemeProvider>
  );
}

export default App;