import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, Chip, Box, Link, Button } from '@mui/material';
import { jobs } from '../data/jobs';
const Resume = () => {

  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 4;

  const totalPages = Math.ceil(jobs.length / ITEMS_PER_PAGE);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentJobs = jobs.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  return (
    <Card sx={{ borderRadius: 2 }}>
      <CardContent>
        <center>
          <Typography variant="h6" style={{ textDecoration: 'none', marginBottom: '5px' }}>My Employment</Typography>
        </center>
        <hr />
      </CardContent>
      <CardContent style={{minHeight: '900px'}}>
        {currentJobs.map((project, index) => (
          <Box key={index} display="flex" flexDirection="column" mb={2}>
            <Box display="flex" alignItems="flex-start">
              <img src={project.image} style={{ height: '100px', width: '100px', marginRight: '16px', borderRadius: 2 }} alt={project.name} />
              <Box>
                <Typography variant="body2" fontWeight="bold" fontSize="1.2rem" style={{textDecoration: 'underline'}}>
                  <Link href={project.link} target="_blank" rel="noopener" color="inherit" underline="none">
                    {project.name}
                  </Link>
                </Typography>
                {project.positions.map((position, posIndex) => (
                  <Box key={posIndex} mt={1}>
                    <Typography variant="body2" fontWeight="bold">
                      {position.title}
                    </Typography>
                    <Typography variant="body2">
                      {position.timeframe}
                    </Typography>
                    <Typography variant="body2">
                      {position.description}
                    </Typography>
                  </Box>
                ))}
                <Box display="flex" flexWrap="wrap" mt={1} justifyContent="flex-end">
                  {project.technologies.map((tech, techIndex) => (
                    <Chip key={techIndex} label={tech} size="small" sx={{ mr: 1, mb: 1 }} />
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
        </CardContent>
          <CardContent>
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Button variant="contained" onClick={handlePreviousPage} disabled={currentPage === 1}>
                Previous
              </Button>
              <Typography variant="body2">
                Page {currentPage} of {totalPages}
              </Typography>
              <Button variant="contained" onClick={handleNextPage} disabled={currentPage === totalPages}>
                Next
              </Button>
            </Box>
        </CardContent>
    </Card>
  );
};

export default Resume;