const jobs = [
    {
      image: "/assets/images/avertium.png",
      name: "Avertium LLC",
      positions: [
        {
          timeframe: "Jan 2022 - Present",
          title: "Principal Software Engineer",
          description: "",
        },
        {
          timeframe: "Jan 2019 - Jan 2022",
          title: "DevOps & R&D Manager / Engineer / Infrastructure",
          description: "",
        },
        {
          timeframe: "Jan 2016 - Dec 2019",
          title: "R&D Manager / Software Engineer",
          description: "",
        }
      ],
      link: "https://www.avertium.com/",
      technologies: ["React", "Node.js", "Express", "Docker", "TailWindCss", "Okta/SSO", "AWS", "PHP", "MySQL", "OpenSearch", "Security", "SIEM", "Agile", "ServiceNow API"]
    },
    {
      image: "/assets/images/s3ntry.png",
      name: "S3ntryCorp",
      positions: [
        {
          timeframe: "May 2024 - Present",
          title: "Contract Software Engineer",
          description: "",
        },
      ],
      link: "https://s3ntrycorp.com/",
      technologies: ["Java SDK", ".Net", "Encryption"]
    },
    {
      image: "/assets/images/catface80.png",
      name: "Ace Pet Care",
      positions: [
        {
          timeframe: "Jun 2022 - Present",
          title: "Web/Software Developer",
          description: "",
        },
      ],
      link: "https://acepetaz.com/",
      technologies: ["PHP", "SQL", "iCal", "Wordpress", "SEO", "Google Analytics"]
    },
    {
      image: "/assets/images/mars.png",
      name: "MARS Suite",
      positions: [
        {
          timeframe: "March 2023 - August 2024",
          title: "Contract Principal Software Architect",
          description: "",
        },
      ],
      link: "https://marssuite.com/",
      technologies: ["React", ".Net", "Docker", "Nginx", "Python", "Elastic Cloud", "Greenbone", "PostGres", "SIEM", "CloudFormation", "OpenVPN", "Lambda"]
    },
    {
      image: "/assets/images/terraverde.jpg",
      name: "Terra Verde Services",
      positions: [
        {
          timeframe: "Oct 2015 - Jan 2016",
          title: "Software Developer / Internal Tools",
          description: "",
        },
        {
          timeframe: "Jun 2015 - Mar 2018",
          title: "Infrastructure Manager",
          description: "",
        },
        {
          timeframe: "Feb 2015 - Jun 2015",
          title: "SOC Analyst",
          description: "",
        },
      ],
      link: "https://www.linkedin.com/in/tvrmsmarketing/",
      technologies: ["ESXI", "Windows", "OSX", "Linux", "AWS", "LDAP", "DNS", "DHCP", "Nginx", "Apache", "MySQL", "Helpdesk", "Security", "SIEM", "Network Administration", "Windows Administration"]
    },
    {
      image: "/assets/images/godaddy.png",
      name: "GoDaddy",
      positions: [
        {
          timeframe: "May 2024 - Present",
          title: "Hosting Online Support Team",
          description: "",
        },
      ],
      link: "https://www.godaddy.com/",
      technologies: ["Customer Support", "Sales", "Domains", "Hosting", "DNS", "Shared Hosting", "Dedicated Servers", "Win/Lin Server Administration", "Wordpress", "Web Development", "Internal Tools Development"]
    },
    // Add more projects as needed
  ];

  module.exports.jobs = jobs;