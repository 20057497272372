// components/GeneralInfo.js
import React, { useState } from 'react';
import { Card, CardContent, Box, Avatar, Typography, Chip, Link, Button } from '@mui/material';

const GeneralInfo = () => {
    const [showMore, setShowMore] = useState(false);
    const skills = ["Node.js", "React", "Material/Elastic UI", "Apache/Nginx/ISS", "Bootstrap/HTML/CSS", "Docker/EKS", "CoPilot/GPT", "MySQL/PostGres", "Elastic/OpenSearch", "AWS Services", "Git/Version Control", "DNS", "Lin/Win Administration", "Fortinet Firewall", "Sophos XG", "Postman", "Automation", "Portainer", "Python", "PHP", "API Integration"]; // Add more skills as needed

    const handleToggle = () => {
        setShowMore(!showMore);
    };

    return (
    <Card sx={{ borderRadius: 2, width: '100%', maxWidth: '1440px', margin: '0 auto' }}>
      <CardContent>
        <Box mb={2}>
            <center>
            <Avatar alt="Your Name" src="/assets/images/me.jpg" sx={{ width: 200, height: 200, mr: 0, mb: 0 }} />

            <Box>
                <Typography variant="h5" component="div" sx={{ mr: 0, mb: 2 }}>
                Ron Egli
                </Typography>
            </Box>
                <Typography variant="body2" color="text.secondary">
                    What people know me as:
                </Typography>
                <Typography variant="body1" color="text.secondary">
                🌟 Accomplished Principal Software Engineer | Security & Innovation Advocate 🌟<br /><br />
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    My Story:
                </Typography>
                {showMore ? (
                    <>
                        <Typography variant="body2" color="text.secondary">
                        With a stellar career in software engineering, I specialize in crafting robust solutions with a focus on Node.js, Python, C#, PHP, as well as many other development languages. My expertise lies in architecting scalable, high-performance web applications that exceed client expectations. <br /><br />
                        Beyond my core competencies, I possess advanced proficiency in containerization and cloud networking, leveraging technologies like Docker to streamline deployment processes. My deep understanding of containerization nuances equips me to navigate challenges effectively while maximizing operational efficiency.<br /><br />
                        In leadership roles, I excel at guiding and mentoring cross-functional teams, ensuring alignment with project objectives and delivering superior outcomes. As a proactive learner, I stay abreast of industry trends and emerging technologies to continuously enhance my skill set.<br /><br />
                        I have a track record of success in developing and maintaining software projects with broad reach, including one with over 15k concurrent installations across hundreds of companies. This project focused on security monitoring, emphasizing my commitment to secure coding practices and the importance of robust testing protocols. Automatic upgrades with rigorous testing were integral to preventing issues and maintaining system integrity.<br /><br />
                        My background includes successful tenures as a development manager, overseeing a talented team of five developers, and as an Infrastructure/IT manager, leading a two-person team. Additionally, I bring expertise in Domains, Hosting, and DNS management, enriching my ability to contribute holistically to projects.<br /><br />
                        As a perpetual tinkerer, I thrive on innovation, continually developing self-hosted tools to enhance productivity and explore new tech stacks. While these tools may not have widespread use, they underscore my commitment to learning and improvement.<br /><br />
                        I am eager to collaborate with forward-thinking teams on challenging projects, leveraging my expertise to drive impactful results. Thank you for considering me for your software engineering needs.
                        </Typography>
                    </>
                    ) : (
                    <Typography variant="body2" color="text.secondary">
                        With a stellar career in software engineering, I specialize in crafting robust solutions with a focus on Node.js, Python, C#, PHP, as well as many other development languages. My expertise lies in architecting scalable, high-performance web applications that exceed client expectations.
                    </Typography>
                )}
                <Button onClick={handleToggle}>
                    {showMore ? 'Read Less' : 'Read More'}
                </Button>
            </center>
        </Box>
        <Box mt={2} display="flex" flexDirection="column" alignItems="center">
            <Typography variant="body2" color="text.secondary">
                Things I Know:
            </Typography>
            <Box mt={1} display="flex" justifyContent="center" flexWrap="wrap">
                {skills.map((skill, index) => (
                    <Chip key={index} label={skill} size="small" sx={{ mr: 1, mb: 1 }} />
                ))}
            </Box>
        </Box>
        <Box mt={2} display="flex" flexDirection="column" alignItems="center">
            <Typography variant="body2" color="text.secondary">
                Where you can find me:
            </Typography>
            <Box mt={1} display="flex" justifyContent="center">
                <Link href="https://www.linkedin.com/in/ronegli" target="_blank" rel="noopener" sx={{ mx: 1 }}>
                    LinkedIn
                </Link>
                <Link href="https://github.com/smugzombie" target="_blank" rel="noopener" sx={{ mx: 1 }}>
                    GitHub
                </Link>
                <Link href="mailto:contact@ronegli.com" target="_blank" rel="noopener" sx={{ mx: 1 }}>
                    Email
                </Link>
            </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default GeneralInfo;